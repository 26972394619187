import React, { useEffect, useState } from 'react'

import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import _ from 'lodash'
import { Grid, Card, Image } from 'semantic-ui-react'
import SwiperCore, { Pagination, Scrollbar, A11y, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import classNames from 'classnames'
import { MdAlarm, MdAttachMoney } from 'react-icons/md'
import { AiFillSafetyCertificate } from 'react-icons/ai'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import CustomChairDisplay from '../components/CustomChairDisplay'
import ResEmbeddedYoutube from '../components/ResEmbeddedYoutube'
import AccessoryChairs from '../components/AccessoryChairs'
import Furniture from '../components/Furniture'

import RosegoldBowl from '../images/bowl-images/rosegold.jpg'
import GoldBowl from '../images/bowl-images/gold.jpg'
import PearlBowl from '../images/bowl-images/pearl.jpg'
import MochaBowl from '../images/bowl-images/mocha.jpg'
import GraphiteBowl from '../images/bowl-images/graphite.jpg'

import IvoryLeather from '../images/chair-leathers/ivory.png'
import BlackLeather from '../images/chair-leathers/black.png'
import CappuccinoLeather from '../images/chair-leathers/cappuccino.png'
import FossilgreyLeather from '../images/chair-leathers/fossil_grey.png'
import DarkchocolateLeather from '../images/chair-leathers/dark_chocolate.png'
import BurgundyLeather from '../images/chair-leathers/burgundy.png'

// Import Swiper styles
// import 'swiper/swiper.scss'
// import 'swiper/components/pagination/pagination.scss'
// import 'swiper/components/scrollbar/scrollbar.scss'

import ProductView from '../components/ProductView'

SwiperCore.use([Pagination, Scrollbar, A11y, Mousewheel])

const BOWLS = [
  { color: 'Rose Gold', src: RosegoldBowl },
  { color: 'Gold', src: GoldBowl },
  { color: 'Pearl', src: PearlBowl },
  { color: 'Graphite', src: GraphiteBowl },
  { color: 'Mocha', src: MochaBowl }
]

const LEATHERS = [
  { color: 'Burgundy', src: BurgundyLeather },
  { color: 'Black', src: BlackLeather },
  { color: 'Cappuccino', src: CappuccinoLeather },
  { color: 'Dark Chocolate', src: DarkchocolateLeather },
  { color: 'Fossil Grey', src: FossilgreyLeather },
  { color: 'Ivory', src: IvoryLeather }
]

const calculateSlidesPerView = screenWidth => {
  if (screenWidth >= 1024) {
    return 6
  }
  if (screenWidth >= 500 && screenWidth < 1024) {
    return 2.3
  }

  return 1.3
}

export const pageQuery = graphql`
  {
    magnaBanner: file(relativePath: { eq: "general/magna-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
    magnaCompactLogo: file(relativePath: { eq: "logos/magna-compact-logo.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 883, layout: CONSTRAINED)
      }
    }
    jetLinerBanner: file(relativePath: { eq: "general/jet-liner-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
    timelineBanner: file(relativePath: { eq: "general/timeline-banner.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
    comparisonBanner: file(relativePath: { eq: "general/comparison-banner.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
    magazine: file(relativePath: { eq: "general/magazine.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
    novoChair: file(relativePath: { eq: "novo-chair/novo.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, layout: CONSTRAINED)
      }
    }
    novoXChair: file(relativePath: { eq: "novox-chair/novox.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, layout: CONSTRAINED)
      }
    }
    tergoChair: file(relativePath: { eq: "tergo-chair/tergo.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, layout: CONSTRAINED)
      }
    }
    magnaChair: file(relativePath: { eq: "magna-chair/magna.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, layout: CONSTRAINED)
      }
    }
    vsportChairN: file(relativePath: { eq: "vsport-chair/vsportn.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, layout: CONSTRAINED)
      }
    }
    vsportChairT: file(relativePath: { eq: "vsport-chair/vsportt.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, layout: CONSTRAINED)
      }
    }
    vipChair: file(relativePath: { eq: "vip-chair/vip.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    majesticChair: file(relativePath: { eq: "majestic-chair/majestic.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTableTower1: file(
      relativePath: { eq: "furniture/nail-tables/tower/nail_table_tower_close_draw.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          width: 500
          height: 500
          quality: 100
          layout: CONSTRAINED
        )
      }
    }
    NailTableTower2: file(
      relativePath: { eq: "furniture/nail-tables/tower/nail_table_tower_open_draw.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          width: 500
          height: 500
          quality: 100
          layout: CONSTRAINED
        )
      }
    }
    NailTable35InchesOne: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable35InchesTwo: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable35InchesThree: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable35InchesFour: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_4.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable35InchesFive: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_5.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable35InchesSix: file(
      relativePath: { eq: "furniture/nail-tables/35-inches/nail_table_35in_6.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesOne: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesTwo: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesThree: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesFour: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_4.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesFive: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_5.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
    NailTable38InchesSix: file(
      relativePath: { eq: "furniture/nail-tables/38-inches/nail_table_38in_6.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

const IndexPage = props => {
  const [activeChairIndex, setActiveChairIndex] = React.useState(0)
  const [isShown, setIsShown] = React.useState(true)
  const [dimensions, setDimensions] = useState(null)

  const pedicureChairsList = [
    {
      chairImgLink: props.data.majesticChair.childImageSharp.gatsbyImageData,
      index: 0,
      model: 'majestic',
      displayName: 'MAJESTIC',
      price: '5,995',
      defaultChairColor: 'fossil_grey',
      defaultBowlColor: 'pearl'
    },
    {
      chairImgLink: props.data.vipChair.childImageSharp.gatsbyImageData,
      index: 1,
      model: 'vip',
      displayName: 'VIP',
      price: '4,995',
      defaultChairColor: 'black',
      defaultBowlColor: 'rose_gold'
    },
    {
      chairImgLink: props.data.magnaChair.childImageSharp.gatsbyImageData,
      index: 2,
      model: 'magna',
      displayName: 'MAGNA',
      price: '4,450',
      defaultChairColor: 'ivory',
      defaultBowlColor: 'rose_gold'
    },
    {
      chairImgLink: props.data.vsportChairT.childImageSharp.gatsbyImageData,
      index: 3,
      model: 'vsportt',
      displayName: 'V-SPORT-T',
      price: '3,750',
      defaultChairColor: 'cappuccino',
      defaultBowlColor: 'graphite'
    },
    {
      chairImgLink: props.data.vsportChairN.childImageSharp.gatsbyImageData,
      index: 4,
      model: 'vsportn',
      displayName: 'V-SPORT-N',
      price: '3,650',
      defaultChairColor: 'fossil_grey',
      defaultBowlColor: 'pearl'
    },
    {
      chairImgLink: props.data.tergoChair.childImageSharp.gatsbyImageData,
      index: 5,
      model: 'tergo',
      displayName: 'TERGO',
      price: '3,550',
      defaultChairColor: 'burgundy',
      defaultBowlColor: 'mocha'
    },

    // {
    //   chairImgLink: props.data.novoChair.childImageSharp.fluid,
    //   index: 5,
    //   model: 'novo',
    //   displayName: 'NOVO',
    //   price: '3,195',
    //   defaultChairColor: 'cappuccino',
    //   defaultBowlColor: 'graphite'
    // },
    {
      chairImgLink: props.data.novoXChair.childImageSharp.gatsbyImageData,
      index: 6,
      model: 'novox',
      displayName: 'NOVO-X',
      price: '3,450',
      defaultChairColor: 'black',
      defaultBowlColor: 'gold'
    }
  ]

  const goToSpaChairView = React.useCallback(e => {
    const { index } = e.currentTarget.dataset
    setIsShown(true)
    setActiveChairIndex(Number(index))

    document
      .getElementById('chair-display')
      .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
  }, [])

  const handlePressEnter = React.useCallback(e => {
    const { index } = e.currentTarget.dataset
    if (e.key === 'Enter') {
      setIsShown(true)
      setActiveChairIndex(Number(index))
      document
        .getElementById('chair-display')
        .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setDimensions({ width: window.screen.width, height: window.screen.height })
    }

    if (dimensions) {
      window.addEventListener('resize', handleResize)
    } else {
      handleResize()
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [dimensions])

  return (
    <Layout>
      <SEO
        title="Pedicure Spa Chair"
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />

      <div className="row py-2">
        <div className="col text-center">
          <h1>CONTÉGO SPA DESIGNS</h1>
          <p className="d-block px-4">PEDICURE SPA MASSAGE CHAIR INNOVATOR</p>
        </div>
      </div>

      <div className="row my-4">
        <div className="col">
          <Grid columns="3" stackable textAlign="center" className="border-top">
            <Grid.Column>
              <strong className="text-secondary">DISPOSABLE JET</strong>
            </Grid.Column>
            <Grid.Column>
              <strong className="text-secondary">EASYDRAIN</strong>
            </Grid.Column>
            <Grid.Column>
              <strong className="text-secondary">INFINITY OVERFLOW</strong>
            </Grid.Column>
          </Grid>
        </div>
      </div>

      <div className="row py-2 bg-secondary">
        <div className="col px-0">
          <Link className="py-2 text-center d-block text-light text-uppercase" to="/about">
            Learn More About Us
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col px-0">
          <GatsbyImage
            image={props.data.magnaBanner.childImageSharp.gatsbyImageData}
            alt="Magna Banner"
          />
          <div className="banner-overlay">
            <GatsbyImage
              image={props.data.magnaCompactLogo.childImageSharp.gatsbyImageData}
              alt="Magna Compact Logo"
            />
            <div className="d-flex flex-column align-items-end">
              <Link
                className="banner-action-button text-center rounded-pill my-2"
                to="/pedicure-spa-chairs/magna"
              >
                Learn More
              </Link>
              <a href="tel:7148958865" className="banner-action-button text-center rounded-pill">
                Call for Quote
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col px-0">
          <h1 className="w-100 px-2 text-center">PEDICURE INDUSTRY EVOLUTION</h1>
          <p className="w-100 px-2 text-center">IMPROVING HYGIENE & SANITIZATION STANDARDS</p>
        </div>
      </div>

      <div className="row mb-4 shadow-sm">
        <div className="col px-0">
          <GatsbyImage
            image={props.data.timelineBanner.childImageSharp.gatsbyImageData}
            alt="Timeline Banner"
          />
        </div>
      </div>

      <div className="container-fluid my-4 bg-secondary px-0 shadow my-4">
        <div className="row">
          <div className="col px-0">
            <GatsbyImage
              image={props.data.jetLinerBanner.childImageSharp.gatsbyImageData}
              alt="JetLiner Banner"
            />
          </div>
        </div>

        <div className="row bg-secondary py-2 px-0">
          <div className="col d-flex flex-column align-items-center bg-secondary text-light">
            <MdAttachMoney size="2rem" />
            <h6 className="text-uppercase text-center pt-2">Increase Salon Income</h6>
          </div>
          <div className="col d-flex flex-column align-items-center bg-secondary text-light">
            <MdAlarm size="2rem" />
            <h6 className="text-uppercase text-center pt-2">Save Time on Cleaning</h6>
          </div>
          <div className="col d-flex flex-column align-items-center bg-secondary text-light">
            <AiFillSafetyCertificate size="2rem" />
            <h6 className="text-uppercase text-center pt-2">Peace of mind sanitation</h6>
          </div>
        </div>
      </div>

      <div className="row py-4 shadow-sm">
        <div className="col px-0">
          <h1 className="mb-4 text-center">
            WE CARE ABOUT YOUR <strong>PEDICURE SAFETY</strong>
          </h1>
          <p className="text-center">
            100% Disposed After Each Use, No Cross-Contamination, Germs are Completely Disposed
          </p>

          <GatsbyImage
            image={props.data.comparisonBanner.childImageSharp.gatsbyImageData}
            className=""
            alt="Comparison Banner"
          />
        </div>
      </div>

      <div className="row py-4 shadow-sm">
        <div className="col px-0">
          <div className="py-4 px-4">
            <h1>SAFER FASTER CLEANER</h1>
            <p>
              In 2015, <strong> the California Board of Barbering and Cosmetology</strong> announced
              new legislation that California nail techs using disposable pedicure liners can skip
              the 10-minute disinfecting soak of their pedicure basin between clients. Enter{' '}
              <strong>Contégo Spa Designs Inc., Pedi-Spa Massage Chair</strong>! This unique chair
              was designed with patented technology that includes a disposable foot spa system
              called the<strong> Contégo Disposable Jet</strong>.
            </p>
          </div>

          <GatsbyImage image={props.data.magazine.childImageSharp.gatsbyImageData} alt="Magazine" />
        </div>
      </div>

      <div className="row bg-black py-4">
        <div className="col text-center text-light">
          <h1>ADVANCED & PATENTED TECHNOLOGIES</h1>
        </div>
      </div>

      <div className="row bg-black py-4">
        <div className="col">
          <ResEmbeddedYoutube url="https://youtu.be/owMKb4xWjuI" width="100%" height="100%" />
        </div>
      </div>

      <div className="row bg-light mt-4">
        <div className="col px-0 py-4 text-center">
          <h1>PEDICURE SPA MASSAGE CHAIRS</h1>

          <div className="text-light bg-dark py-5">
            <h2 className="text-center">LEATHERS</h2>
            {/* <p className="text-center px-4">
              All CONTÉGO leathers are accestone resistant. Please refer to our leather care guide
              for maintaining.
            </p> */}

            <div className="d-flex flex-wrap align-items-center justify-content-center">
              {LEATHERS.map(leather => {
                return (
                  <div key={leather.color} role="button" className="button px-2 py-2">
                    <img
                      src={leather.src}
                      width="150px"
                      height="150px"
                      alt={`${leather.color} bowl`}
                      className="rounded-circle"
                    />
                    <h5 className="text-center my-0 pt-2">{_.upperCase(leather.color)}</h5>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="shadow-sm bg-white py-5">
            <h2 className="text-center">BOWLS</h2>
            <p className="text-center px-4">
              Our beautiful high gloss and textured bowls are made from fiberglass to ensure the
              beauty and durability due to its heavy usage in the pedicure spa chairs.{' '}
            </p>

            <div className="d-flex flex-wrap align-items-center justify-content-center">
              {BOWLS.map(bowl => {
                return (
                  <div key={bowl.color} role="button" className="button px-2 py-2">
                    <img
                      src={bowl.src}
                      width="80px"
                      height="80px"
                      alt={`${bowl.color} bowl`}
                      className="rounded-circle"
                    />
                    <h5 className="text-center">{_.upperCase(bowl.color)}</h5>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="row bg-light">
        <div className="col">
          <h2 className="text-center">MODELS</h2>
          {dimensions && dimensions.width < 1024 && (
            <h4 className="m-0 px-4 text-secondary text-center">Swipe Below To View More</h4>
          )}
        </div>
      </div>

      {dimensions && (
        <div className="row bg-light py-4">
          {dimensions.width < 1024 ? (
            <div className="col">
              <Swiper
                slidesPerView={calculateSlidesPerView(dimensions.width)}
                spaceBetween={10}
                pagination={{ clickable: true }}
                mousewheel={{ forceToAxis: true }}
                className="px-1 py-2"
              >
                {pedicureChairsList.map(pedicureChair => {
                  return (
                    <SwiperSlide key={pedicureChair.displayName}>
                      <Card
                        data-index={pedicureChair.index}
                        onClick={goToSpaChairView}
                        onKeyPress={handlePressEnter}
                        raised
                      >
                        <GatsbyImage
                          image={pedicureChair.chairImgLink}
                          alt={pedicureChair.displayName}
                          wrapped
                          className="py-2"
                        />

                        <Card.Content>
                          <Card.Header>
                            {pedicureChair.displayName}{' '}
                            <h4 className="text-secondary">$ {pedicureChair.price}</h4>
                          </Card.Header>
                        </Card.Content>
                      </Card>
                    </SwiperSlide>
                  )
                })}
                <br /> <br />
              </Swiper>
            </div>
          ) : (
            <div className="w-100 d-flex justify-content-center align-items-center">
              {pedicureChairsList.map(pedicureChair => {
                return (
                  <div
                    style={{
                      width: `${dimensions.width / 8}px`,
                      marginLeft: '5px',
                      marginRight: '5px'
                    }}
                    key={pedicureChair.displayName}
                  >
                    <Card
                      data-index={pedicureChair.index}
                      onClick={goToSpaChairView}
                      onKeyPress={handlePressEnter}
                      raised
                      fluid
                    >
                      <GatsbyImage
                        image={pedicureChair.chairImgLink}
                        alt={pedicureChair.displayName}
                        wrapped
                        className="p-2 img-fluid"
                      />

                      <Card.Content>
                        <Card.Header>
                          {pedicureChair.displayName}{' '}
                          <h4 className="text-secondary">$ {pedicureChair.price}</h4>
                        </Card.Header>
                      </Card.Content>
                    </Card>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      )}

      <div className="row shadow-sm" id="chair-display">
        <div className="col">
          {isShown &&
            pedicureChairsList.map(pedicureChair => {
              return (
                <div
                  key={pedicureChair.model}
                  className={classNames('pedicure-chair py-4', {
                    active: pedicureChair.index === activeChairIndex
                  })}
                >
                  <div>
                    <CustomChairDisplay
                      actionButton
                      model={pedicureChair.model}
                      displayName={pedicureChair.displayName}
                      des={pedicureChair.des}
                      price={pedicureChair.price}
                      defaultChairColor={pedicureChair.defaultChairColor}
                      defaultBowlColor={pedicureChair.defaultBowlColor}
                    />
                  </div>
                </div>
              )
            })}
        </div>
      </div>

      <div className="row py-4 bg-light">
        <div className="col">
          <h1 className="w-100 text-center py-4">MASSAGE CHAIR ACCESSORIES</h1>
          <Grid columns="16" className="py-4">
            <Grid.Column className="py-0" largeScreen="16" computer="16" tablet="16" mobile="16">
              <AccessoryChairs
                bg="manistool-bg"
                model="manistool"
                name="Mani Stool"
                des="Maids table how learn drift but purse stand yet set. Music me house could among oh as their. Piqued our sister shy nature almost his wicket. Hand dear so we hour to. He we be hastily offence effects he service."
                defaultChairColor="burgundy"
                defaultTexture="regular"
                price="175"
              />
            </Grid.Column>

            <Grid.Column className="py-0" largeScreen="16" computer="16" tablet="16" mobile="16">
              <AccessoryChairs
                bg="pedistool-bg"
                model="pedistool"
                name="Pedi Stool"
                des="Maids table how learn drift but purse stand yet set. Music me house could among oh as their. Piqued our sister shy nature almost his wicket. Hand dear so we hour to. He we be hastily offence effects he service."
                defaultChairColor="black"
                defaultTexture="regular"
                price="225"
              />
            </Grid.Column>

            <Grid.Column className="py-0" largeScreen="16" computer="16" tablet="16" mobile="16">
              <AccessoryChairs
                bg="techchair-bg"
                model="techchair"
                name="Tech Chair"
                des="Maids table how learn drift but purse stand yet set. Music me house could among oh as their. Piqued our sister shy nature almost his wicket. Hand dear so we hour to. He we be hastily offence effects he service."
                defaultChairColor="cappuccino"
                defaultTexture="regular"
                price="235"
              />
            </Grid.Column>
          </Grid>

          <Grid columns="1">
            <Grid.Column className="py-0">
              <AccessoryChairs
                model="customerchairsofa"
                name="Customer Chair - Sofa Style"
                des="Maids table how learn drift but purse stand yet set. Music me house could among oh as their. Piqued our sister shy nature almost his wicket. Hand dear so we hour to. He we be hastily offence effects he service."
                defaultChairColor="black"
                defaultTexture="regular"
                price="495"
              />
            </Grid.Column>

            <Grid.Column className="py-0">
              <AccessoryChairs
                model="customerchair"
                name="Customer Chair"
                des="Maids table how learn drift but purse stand yet set. Music me house could among oh as their. Piqued our sister shy nature almost his wicket. Hand dear so we hour to. He we be hastily offence effects he service."
                defaultChairColor="ivory"
                defaultTexture="regular"
                price="375"
              />
            </Grid.Column>
          </Grid>
        </div>
      </div>

      <Furniture name="DIP POWDER CABINET" model="DipPowderCabinet" price="995" />
      <Furniture name="GEL POLISH CABINET" model="GelPolishCabinet" price="995" />

      <div className="row d-flex align-items-center justify-content-between shadow-sm">
        <div className="col-lg-6">
          <ProductView
            price="945"
            name='NAIL TABLE 35"'
            items={[
              {
                title: 'Nail Table 35" inches close draws',
                type: 'img',
                url: props.data.NailTable35InchesOne.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 35" side view',
                type: 'img',
                url: props.data.NailTable35InchesTwo.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 35" opened left draw',
                type: 'img',
                url: props.data.NailTable35InchesThree.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 35" opened center draw',
                type: 'img',
                url: props.data.NailTable35InchesFour.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 35" opened right draw',
                type: 'img',
                url: props.data.NailTable35InchesFive.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 35" opened draws',
                type: 'img',
                url: props.data.NailTable35InchesSix.childImageSharp.gatsbyImageData
              }
            ]}
          />
        </div>

        <div className="col-lg-6">
          <ProductView
            price="995"
            name='NAIL TABLE 38"'
            items={[
              {
                title: 'Nail Table 38" inches close draws',
                type: 'img',
                url: props.data.NailTable38InchesOne.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 38" side view',
                type: 'img',
                url: props.data.NailTable38InchesTwo.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 38" opened left draw',
                type: 'img',
                url: props.data.NailTable38InchesThree.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 38" opened center draw',
                type: 'img',
                url: props.data.NailTable38InchesFour.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 38" opened right draw',
                type: 'img',
                url: props.data.NailTable38InchesFive.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table 38" opened draws',
                type: 'img',
                url: props.data.NailTable38InchesSix.childImageSharp.gatsbyImageData
              }
            ]}
          />
        </div>
      </div>

      <div className="row shadow-sm px-2 rounded">
        <div className="col">
          <ProductView
            price="495"
            name="LUNA NAIL TABLE TOWER"
            items={[
              {
                title: 'Nail Table Tower Close Draw',
                type: 'img',
                url: props.data.NailTableTower1.childImageSharp.gatsbyImageData
              },
              {
                title: 'Nail Table Tower Open Draw',
                type: 'img',
                url: props.data.NailTableTower2.childImageSharp.gatsbyImageData
              }
            ]}
          />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
